.video-item h2,
.video-item p {
  text-align: center;
}

.wp-video {
  display: block;
  margin: 20px auto;

  border: 10px solid var(--main-dark);
  box-shadow: 0px 0px 100px rgba(30, 30, 31, 0.15);

  box-sizing: content-box;
}