@import 'bootstrap-grid.css';
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400');

:root {
    --main: #3382C5;
    --main-dark: #213C6C;
    --main-light: #45AAFF;
    --acsent: #F35653;
    --acsent-light: #F8F8F8;
}

* {
    box-sizing: border-box;
}

html {
    overflow-x: hidden;
}

body {
    font-family: 'Roboto', sans-serif;
    overflow-x: hidden;
    overflow-y: scroll;
}

img {
    width: 100%;
    height: auto;
    user-select: none;
}

iframe {
    user-select: none;
}

form input,
form textarea {
    outline: none;
}

button {
    cursor: pointer;
}

textarea {
    resize: vertical;
}

.container-fluid {
    padding-left: 5%;
    padding-right: 5%;
}

.container-fluid>.row {
    margin-left: 0;
    margin-right: 0;
}

/* VENDOR */

.slick-dots li.slick-active button:before {
    background-color: #5CAFE2;
}

.slick-dots li button:before {
    content: '';
    background-color: gray;
    border-radius: 50%;
}

.slick-dots {
    bottom: -50px;
}

/* EDITOR STYLES */


blockquote {
	border-left: 4px solid #1a1a1a;
	color: #686868;
	font-size: 19px;
	font-style: italic;
	line-height: 1.4736842105;
	margin-bottom: 28px;
	overflow: hidden;
	padding: 0 0 0 24px;
}

blockquote:not(.alignleft):not(.alignright) {
	margin-left: -28px;
}

blockquote blockquote:not(.alignleft):not(.alignright) {
	margin-left: 0;
}

blockquote:before,
blockquote:after {
	content: "";
	display: table;
}

blockquote:after {
	clear: both;
}

blockquote > :last-child {
	margin-bottom: 0;
}

blockquote cite,
blockquote small {
	color: #1a1a1a;
	font-size: 16px;
	line-height: 1.75;
}

blockquote em,
blockquote i,
blockquote cite {
	font-style: normal;
}

blockquote strong,
blockquote b {
	font-weight: 400;
}

blockquote.alignleft,
blockquote.alignright {
	border: 0 solid #1a1a1a;
	border-top-width: 4px;
	padding: 18px 0 0;
	width: -webkit-calc(50% - 14px);
	width: calc(50% - 14px);
}

address {
	font-style: italic;
	margin: 0 0 28px;
}

code,
kbd,
tt,
var,
samp,
pre {
	font-family: Inconsolata, monospace;
}

pre {
	border: 1px solid #d1d1d1;
	font-size: 16px;
	line-height: 1.3125;
	margin: 0 0 28px;
	max-width: 100%;
	overflow: auto;
	padding: 14px;
	white-space: pre;
	white-space: pre-wrap;
	word-wrap: break-word;
}

code {
	background-color: #d1d1d1;
	padding: 2px 4px;
}

abbr[title] {
	border-bottom: 1px dotted #d1d1d1;
	cursor: help;
}

mark,
ins {
	background: #007acc;
	color: #fff;
	padding: 2px 4px;
	text-decoration: none;
}

sup,
sub {
	font-size: 75%;
	height: 0;
	line-height: 0;
	position: relative;
	vertical-align: baseline;
}

sub {
	top: -6px;
}

sup {
	bottom: -3px;
}

small {
	font-size: 80%;
}

big {
	font-size: 125%;
}


/**
 * 3.0 - Elements
 */

hr {
	background-color: #d1d1d1;
	border: 0;
	height: 1px;
	margin-bottom: 28px;
}

ul,
ol {
	margin: 0 0 28px 0;
	padding: 0;
}

ul {
	list-style: disc;
}

ol {
	list-style: decimal;
}

li > ul,
li > ol {
	margin-bottom: 0;
}

li > ul,
blockquote > ul {
	margin-left: 20px;
}

li > ol,
blockquote > ol {
	margin-left: 24px;
}

dl {
	margin: 0 0 28px;
}

dt {
	font-weight: bold;
}

dd {
	margin: 0 0 28px;
}

table,
th,
td,
.mce-item-table,
.mce-item-table th,
.mce-item-table td {
	border: 1px solid #d1d1d1;
}

table a {
	color: #007acc;
}

table,
.mce-item-table {
	border-collapse: separate;
	border-spacing: 0;
	border-width: 1px 0 0 1px;
	margin: 0 0 28px;
	width: 100%;
}

table th,
.mce-item-table th,
table caption {
	border-width: 0 1px 1px 0;
	font-size: 16px;
	font-weight: 700;
	padding: 7px;
	text-align: left;
	vertical-align: baseline;
}

table td,
.mce-item-table td {
	border-width: 0 1px 1px 0;
	font-size: 16px;
	padding: 7px;
	vertical-align: baseline;
}

img {
	border: 0;
	height: auto;
	max-width: 100%;
	vertical-align: middle;
}

a img {
	display: block;
}

a.__withoutLine {
  text-decoration: none;
}

figure {
	margin: 0;
}

del {
	opacity: 0.8;
}

fieldset {
	border: 1px solid #d1d1d1;
	margin: 0 0 28px;
	padding: 14px;
}


/**
 * 4.0 - Alignment
 */

.alignleft {
	float: left;
	margin: 6px 28px 28px 0;
}

.alignright {
	float: right;
	margin: 6px 0 28px 28px;
}

.aligncenter {
	clear: both;
	display: block;
	margin: 0 auto 28px;
}


/**
 * 5.0 - Caption
 */

.wp-caption {
	background: transparent;
	border: none;
	margin-bottom: 28px;
	max-width: 100%;
	padding: 0;
	text-align: inherit;
}

.wp-caption-text,
.wp-caption-dd {
	color: #686868;
	font-size: 13px;
	font-style: italic;
	line-height: 1.6153846154;
	padding-top: 7px;
}


/**
 * 6.0 - Galleries
 */

.mce-content-body .wpview-wrap {
	margin-bottom: 28px;
}

.gallery {
	margin: 0 -1.1666667%;
	padding: 0;
}

.gallery .gallery-item {
	display: inline-block;
	max-width: 33.33%;
	padding: 0 1.1400652% 2.2801304%;
	text-align: center;
	vertical-align: top;
	width: 100%;
}

.gallery-columns-1 .gallery-item {
	max-width: 100%;
}

.gallery-columns-2 .gallery-item {
	max-width: 50%;
}

.gallery-columns-4 .gallery-item {
	max-width: 25%;
}

.gallery-columns-5 .gallery-item {
	max-width: 20%;
}

.gallery-columns-6 .gallery-item {
	max-width: 16.66%;
}

.gallery-columns-7 .gallery-item {
	max-width: 14.28%;
}

.gallery-columns-8 .gallery-item {
	max-width: 12.5%;
}

.gallery-columns-9 .gallery-item {
	max-width: 11.11%;
}

.gallery .gallery-caption {
	font-size: 13px;
	margin: 0;
}

.gallery-columns-6 .gallery-caption,
.gallery-columns-7 .gallery-caption,
.gallery-columns-8 .gallery-caption,
.gallery-columns-9 .gallery-caption {
	display: none;
}


/**
 * 7.0 - Audio / Video
 */

.wp-audio-shortcode a,
.wp-playlist a {
	box-shadow: none;
}

.mce-content-body .wp-audio-playlist {
	margin: 0;
	padding-bottom: 0;
}

.mce-content-body .wp-playlist-tracks {
	margin-top: 0;
}

.mce-content-body  .wp-playlist-item {
	padding: 10px 0;
}

.mce-content-body .wp-playlist-item-length {
	top: 10px;
}


/**
 * 8.0 - RTL
 */

.rtl blockquote {
	border: 0 solid #1a1a1a;
	border-right-width: 4px;
}

.rtl blockquote.alignleft,
.rtl blockquote.alignright {
	border: 0 solid #1a1a1a;
	border-top-width: 4px;
}

.rtl blockquote:not(.alignleft):not(.alignright) {
	margin-right: -28px;
	padding: 0 24px 0 0;
}

.rtl blockquote blockquote:not(.alignleft):not(.alignright) {
	margin-right: 0;
	margin-left: auto;
}

.rtl li > ul,
.rtl blockquote > ul {
	margin-right: 20px;
	margin-left: auto;
}

.rtl li > ol,
.rtl blockquote > ol {
	margin-right: 24px;
	margin-left: auto;
}

.rtl table th,
.rtl .mce-item-table th,
.rtl table caption {
	text-align: right;
}

img.size-medium {
    width: 300px;
    height: 275px;
    object-fit: contain;
}

img.size-thumbnail {
    width: 150px; height: 150px;
    object-fit: contain;
}

img.size-full {
		height: unset;
		width: unset;
}