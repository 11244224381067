/* ELEMENTS */

main>div:not(#main) {
    margin-top: 120px;
    margin-bottom: 50px;
}

.prev-arrow {
    left: 0;
}

.next-arrow {
    right: 0;
}

.prev-arrow,
.next-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%)
}

.section-full {
    height: calc(100vh - 100px);
    min-height: 500px;
}

section:not(.section-full) {
    padding: 75px 0;
}

.section-dark {
    background-color: var(--main-dark);
}

.section-light {
    background-color: var(--main-light);
}

.section-acsent {
    background-color: var(--acsent);
}

.section-acsent-light {
    background-color: var(--acsent-light);
}

.section-dark:not(.section-side-left) p,
.section-light:not(.section-side-left) p,
.section-dark:not(.section-side-left) h1,
.section-light:not(.section-side-left) h1,
.section-dark:not(.section-side-left) h2,
.section-light:not(.section-side-left) h2,
.section-dark:not(.section-side-left) a,
.section-light:not(.section-side-left) a,
.section-dark:not(.section-side-left)  .section-title .section-name,
.section-dark:not(.section-side-left)  .section-title .section-description {
    color: white;
}

.section-side-left {
    background: linear-gradient(90deg, var(--main) 25%, white 25%)
}

.section-side-left.section-dark {
    background: linear-gradient(90deg, var(--main-dark) 25%, white 25%)
}

.title-left {
    text-align: left;
}

.section-name {
    font-size: 16px;
    color: gray;
    font-weight: 300;
}

.section-description {
    font-size: 36px;
    margin-top: 0;
}

section p {
    font-size: 16px;
    font-weight: 300;
    line-height: 1.5;
}

.section-title {
    position: relative;
    margin-bottom: 40px;
    padding-bottom: 20px;
}

.title-center {
    text-align: center;
}

.section-title:not(.section-title-simplified)::after {
    content: '';
    background-color: #F07F84;
    height: 1px;
    width: 100px;
    position: absolute;
    bottom: 0;
}

.section-dark:not(.section-side-left) .section-title::after {
    background-color: white;
}

.title-center.section-title::after {
    left: 50%;
    transform: translateX(-50%);
}

.button {
    display: block;
    padding: 20px 40px;
    background: none;
    border: none;
    font-size: 14px;
    font-weight: 300;
    text-transform: uppercase;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s;
    text-decoration: none;
}

.button-acsent {
    background-color: var(--acsent);
    border: 1px solid var(--acsent);
}

.button-acsent:hover {
    background-color: rgb(240, 50, 47);
}

.button-light {
    background-color: var(--main-light);
}

.button-light:hover {
    background-color: var(--main);
}

.button-ghost {
    border: 1px solid white;
}

.button-ghost:hover {
    background-color: white;
    color: black;
}

.image-bordered img,
.image-bordered > div,
.image-bordered > video {
    border: 10px solid white;
    box-shadow: 0px 0px 100px rgba(30, 30, 31, 0.15);
}

.filter-item {
    font-size: 16px;
    border: none;
    color: black;
    padding: 10px 0;
    margin: 5px 15px;
    background: none;
}

.filter-item.active {
    border-bottom: 1px solid gray;
    color: gray;
}

.filter-item.hover {
    color: gray;
}

.filter-item:focus {
    outline: none;
    border-bottom: 1px solid gray;
}

/* POSTS */

.post-single {
    overflow: scroll;
}

.post-single p {
    font-size: 24px;
    line-height: 1.5;
}

.post-single-back {
    background: none;
    border: none;
}

/* SECTIONS */

/* HEADER */

header {
    height: 100px;
    overflow: hidden;
    position: fixed;
    top: 0;
    background-color: white;
    z-index: 8;
    box-shadow: 0px 5px 10px rgba(0,0,0,0.3);
}

.header-brand {
    width: 350px;
    height: 100px;
}

.header-nav-item {
    padding: 30px 15px;
    font-size: 14px;
    font-weight: 300;
    color: gray;
    text-transform: uppercase;
    text-decoration: none;
}

.header-nav-item:hover {
    text-decoration: underline;
}

.header-controls {
    text-align: end;
}

.nav-mobile-wrapper {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0,0.8);
    transition: opacity 0.3s ease-in;
    opacity: 0;
    z-index: 10;
}

.nav-mobile {
    position: fixed;
    left: -30vw;
    top: 0;
    width: 30vw;
    background-color: rgb(37, 37, 37);
    height: 100vh;
    transition: left 0.3s ease-out;
}

.header-nav-mobile-item {
    display: block;
    padding: 20px;
    background-color: rgb(53, 53, 53);
    color: white;
    text-decoration: none;
    border-bottom: 1px solid black;
}

.mobile-burger {
    background: none;
    border: none;
    outline: none;
}

.mobile-burger img {
    width: 35px;
    height: 35px;
}

/* BANNER */

#banner {
    margin-top: 100px;
}

#banner .banner-subtitle {
    text-align: center;
}

.banner-logo {
    /* width: 425px; */
    width: 100%;
    height: 190px;
    object-fit: contain;
    margin-bottom: 5px;
}

.banner-title {
    font-size: 14px;
    font-weight: 300;
    text-transform: uppercase;
    text-align: center;
}

.banner-controls button {
    margin: 0 10px
}

/* PROGRESS */

.about-image {
    width: 950px;
    margin-right: 50px;
}

.about-image img {
    min-height: 300px;
    object-fit: cover;
}

.progress-description {
    text-align: center;
}

.progress-step {
    position: relative;
}

.progress-step::after,
.progress-step::before {
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}

.progress-step::before {
    content: '';
    position: absolute;
    bottom: -20px;
    left: 50%;
    transform: translateX(-50%);
    width: 36px;
    height: 6px;
    background-image: url('wave.png');
}

.progress-step::after {
    content: '';
    position: absolute;
    top: 30%;
    right: -30%;
    transform: translateY(-50%);
    width: 20px;
    height: 40px;
    background-image: url('arrow-right.png');
}

.progress-step:last-child::after {
    display: none;
}

/* COMITETS */
.comitets-container {
  /* height: 600px; */
}

.comitets-item {
    margin: 20px 0;
    padding: 0 10px;
    /* height: 200px; */
}

.comitets-item p {
    text-align: center;
}

.comitets-item-thumbnail {
    background-color: var(--main);
    border-radius: 50%;
    box-shadow: rgba(0,0,0,0.2) 0 2px 8px;
    width: 120px;
    height: 120px;
    margin: 0 auto;
}

.comitets-item-thumbnail img {
    width: 64px;
    height: 64px;
    object-fit: contain;
}

/* ABOUT */


.documents-popup-item {
    margin: 20px 0;
}

.documents-popup-item-link {
    color: black;
}

/* PUBLICATIONS */

.publication-item {
    background-size: cover;
    background-position: center;
    margin: 30px 15px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.publication-item .publication-content {
    height: 175px;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 10px 30px;
}

.publication-excerpt {
    color: white;
    font-size: 14px;
    line-height: 1.8;
    font-weight: 300;
    height: 100px;
    overflow-y: auto;
}

.publication-link {
    color: white;
    text-decoration: none;
}

.publication-link:hover {
    border-bottom: 1px solid white;
}

.publication-compact {
    width: 370px;
    height: 320px;
}

.publication-large {
    width: 370px;
    height: 670px;
}

/* EMPLOYER MAP */

.map-privilege {
    margin: 15px;
}

.privilege-icon {
    width: 60px;
    height: 60px;
    background: #FFFFFF;
    border: 0.8px solid #0062A8;
    box-sizing: border-box;
    box-shadow: 0px 3.2px 16px rgba(0, 0, 0, 0.15);
    border-radius: 50%;
    padding: 15px;
    margin: 0 auto;
    display: flex;
    align-items: center;
}

.privilege-description {
    font-size: 11px;
    color: var(--main-dark);
    text-align: center;
}

.map-image {
    position: absolute;
    left: 0;
    top: 0;
    min-width: 300px;
    max-width: 800px;
    width: 50vw;
}

/* MANAGEMENT */

.management-card {
    width: 270px;
    height: 275px;
    margin: 12px;
    display: flex;
    align-items: flex-end;
    background-position: center;
    background-size: cover;
    position: relative;
    object-fit: cover;
}

.management-card:first-child {
    margin-left: 0;
}

.management-leader-name {
    font-size: 24px;
    margin: 0;
}

.management-leader-description p {
    margin-top: 7vh;
    font-size: 18px;
    font-weight: 400;
    white-space: pre-line;
    max-height: 200px;
    overflow: auto;
}

.management-info {
    background: rgba(0, 0, 0, 0.5);
    height: 70px;
    width: 100%;
    text-align: center;
}

.management-info .management-name {
    font-size: 16px;
    color: white;
    font-weight: 300;
    margin-bottom: 5px;
}

.management-info .management-post {
    font-size: 14px;
    color: white;
    font-weight: 300;
    margin-top: 5px;
}

.management-leader-photo {
    margin-right: 24px;
}

.management-all-title {
    text-align: center;
    text-transform: uppercase;
    font-weight: 300;
    position: relative;
    padding-bottom: 20px;
    margin-bottom: 50px;
}

.management-all-title::after {
    content: '';
    background-color: var(--acsent);
    height: 1px;
    width: 100px;
    position: absolute;
    transform: translateX(-50%);
    left: 50%;
    bottom: 0;
}

.management-section {
    border-bottom: 1px solid lightgray;
}

.management-section:last-child {
    border: none;
}

.management-section ul {
    list-style: none;
    margin-left: 0;
}

.management-section li {
    padding: 10px 0;
}

.management-file-link {
    text-transform: uppercase;
    font-size: 1.3rem;
    text-align: center;
    color: black;
}

/* ACHIEVES | REVIEWS */

.achieve-icon {
    width: 45px;
    height: 45px;
    object-fit: contain;
}

.achieve-number {
    font-size: 42px;
    font-weight: 400;
    margin: 0;
    text-align: center;
}

.achieve-description {
    font-size: 16px;
    color: gray;
    margin-top: 10px;
    text-align: center;
    height: 75px;
    overflow-y: auto;
}

.achievement-item {
    padding: 50px 60px;
    border: 1px solid lightgray;
}

.achievement-item.__withHover:hover {
	box-shadow: inset 2px 5px 16px 0px #0b325e, inset 5px 5px 15px 5px rgba(0, 0, 0, 0);
}

.author-photo {
    width: 83px;
    height: 83px;
    border-radius: 50%;
    margin-left: auto;
    margin-right: auto;
}

.author-name {
    color: white;
    font-size: 18px;
    font-weight: 300;
    text-align: center;
}

.author-occupation {
    color: white;
    font-size: 14px;
    font-weight: 300;
    text-align: center;
}

.review-text {
    text-align: center;
    font-size: 18px;
    line-height: 2;
}

.review-wrapper {
    max-width: 500px;
    width: fit-content;
    margin: 0 auto;
}

.author-social-item {
    margin: 5px;
}

.achievments-wrapper {
    margin-left: 0;
    margin-right: 0;
}

/* STEPS */

.step {
    background: #FFFFFF;
    box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.15);
    border-radius: 50px;
    padding: 10px 30px;
    width: 300px;
    height: 90px;
    margin: 0 80px;
    position: relative;
}

.step:last-child {
    margin-right: 0;
}

.step:first-child {
    margin-left: 0;
}

.step-content {
    color: #F35653;
    text-align: center;
    margin: 0;
}

.step:after {
    content: '';
    transform: translateY(-50%);
    width: 20px;
    height: 40px;
    background-image: url('arrow-right.png');
    position: absolute;
    top: 50%;
    right: -30%;
}

.step:last-child::after {
    display: none;
}

/* PAYMENT */

.payment-item {
    width: 350px;
    background: #FFFFFF;
    box-shadow: 0px 0px 50px rgba(92, 175, 226, 0.4);
}

.payment-header {
    border-bottom: 1px solid gray;
}

.payment-name {
    font-size: 18px;
    font-weight: 300;
    letter-spacing: 5.5px;
    text-align: center;
    text-transform: uppercase;
    margin-top: 40px;
    margin-bottom: 10px;
}

.payment-price {
    font-size: 36px;
    font-weight: 400;
    text-align: center;
    margin-top: 10px;
}

.payment-price>span {
    color: lightgray;
    font-size: 14px;
}

.payment-content {
    padding: 50px;
    padding-top: 20px;
}

.payment-content-description {
    font-size: 14px;
    line-height: 2;
    color: gray;
    margin-bottom: 30px;
    text-align: center;
}

.payments-documents ul {
    list-style: none;
}

.payments-documents li {
    padding: 20px 0;
}

.payments-documents a {
    color: black;
}

/* PARTNERS */

.partners-container {
  padding-bottom: 50px;
}

.partner-item {
    height: 100px !important;
    background-color: white;
    border-radius: 20px;
    box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.15);
    padding: 5px 10px;
    position: relative;
}

.partner-item a {
    width: 100%;
    height: 100%;
}

.partner-item img {
    object-fit: contain;
    width: 100%;
    height: 100%;
}

.partners-video {
    padding: 0 20px;
    min-height: 50vh;
}

.partners-video video {
    min-height: 40vh;
}

/* CONTACTS */

.contact-input-row {
    margin-top: 15px;
    margin-bottom: 15px;
}

.contact-input-item,
.contact-input-text {
    width: 100%;
    border: 1px solid #ECECEC;
    padding: 15px;
}

.contacts-map {
    min-height: 350px
}

.social-contact-logolink {
    width: 50px;
    height: 50px;
}

/* ADRESS */
.adress-city {
    font-size: 36px;
    text-transform: uppercase;
    text-align: center;
    font-weight: 300;
}

.adress-description {
    text-align: center;
    font-size: 14px;
    line-height: 2;
}

/* BUREAU */

.bureau-leader {
    margin-top: 0.35em;
}

.bureau-leader-image {
    width: 260px;
    height: 260px;
}


/* FOOTER */
footer {
    padding: 75px 0 20px 0;
}

.footer-title-wrapper {
    position: relative;
    margin-bottom: 50px;
}

.footer-title-wrapper::after {
    content: '';
    width: 42px;
    height: 4px;
    background-color: white;
    position: absolute;
    bottom: -25px;
    left: 0;
}

.footer-title {
    font-size: 24px;
    color: white;
}

.footer-links-item {
    margin: 15px 0;
}

.footer-links-item-link {
    color: darkgray;
    font-size: 14px;
    text-decoration: none;
    padding: 20px;
    padding-left: 0;
}

.footer-links-item:hover {
    text-decoration: underline;
}

.footer-letter-input-submit-button,
.footer-letter-input-field {
    background: none;
    border: none;
    padding: 0 0 10px 0;
}

.footer-letter-input-field {
    font-size: 14px;
    font-weight: 400;
    color: white;
}

.footer-letter-input-submit-button img {
    width: 20px;
    height: 15px;
    margin-left: auto;
    display: block;
}

.footer-letter-input {
    border-bottom: 2px solid white;
}

.footer-social-item {
    margin: 0 10px;
}

.footer-credits-text,
.footer-credits-text a {
    color: #75757D;
    font-size: 14px;
}

.footer-credits-text span,
.footer-credits-text a {
    color: white;
    text-decoration: none;
}

.footer-credits-text.contact {
    text-align: right;
}

/* XL */
@media(max-width: 1440px) {
    .progress-description {
        font-size: 14px;
    }

    .step {
        margin: 0 40px;
        width: 200px;
    }

    .step:after {
        right: -25%;
    }
}

/* LG */
@media(max-width: 1200px) {
    .publication-item {
        min-height: 320px;
        padding: 0;
    }

    .publication-item .publication-content {
        padding: 0 30px;
    }

    .about-image {
        padding-right: 0;
    }
    .step {
        margin: 20px 0;
    }

    .step:after {
        display: none;
    }

}

/* MD */
@media(max-width: 992px) {
    .section-side-left.section-dark {
        background: linear-gradient(to bottom, var(--main-dark) 45%, white 45%)
    }
    .section-side-left {
        background: linear-gradient(to bottom, var(--main) 45%, white 45%)
    }

    .progress-step {
        margin: 30px 0;
    }

    .progress-step::after {
        display: none;
    }
 
    .map-image {
        position: relative;
    }

    .footer-credits-text,
    .footer-credits-text.contact {
        text-align: center;
    }
}

/* SM */
@media(max-width: 568px) {
    .review-wrapper {
        max-width: 320px;
    }
    .header-brand {
        width: 200px;
    }
    .section-side-left {
        background: linear-gradient(to bottom, var(--main) 15%, white 15%)
    }
    .section-side-left.section-dark {
        background: linear-gradient(to bottom, var(--main-dark) 15%, white 15%)
    }

    .section-content {
        padding-left: 10px !important;
        padding-right: 10px !important;
    }

    .achieve-number {
        font-size: 32px;
    }

    .nav-mobile {
        width: 65vw;
    }
    
    .management-leader-photo {
        margin-right: 0;
    }

    .review-item {
        padding: 0 15px;
    }

    .contact-input-row input {
        margin-top: 15px;
        margin-bottom: 15px;
    }

    .button {
        padding: 20px 30px;
    }

    .publication-item .publication-content {
        padding: 0 10px;
    }
    .achieve-number {
        font-size: 32px;
    }

    .publication-compact {
        width: 300px;
    }

    .map-privilege {
        width: 100px !important;
    }

    .payment-item {
        width: 280px;
    }
    .payments-documents ul,
    .management-section ul {
        padding-left: 0;
    }
}


.swiper-pagination-bullet {
  width: 18px;
  height: 18px;
}

.swiper-container-horizontal>.swiper-pagination-bullets, .swiper-pagination-custom, .swiper-pagination-fraction {
  bottom: 0;
}
