@import url(https://fonts.googleapis.com/css?family=Roboto:300,400);
/*!
 * Bootstrap Grid v4.1.3 (https://getbootstrap.com/)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1200px;
  }
}

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.no-gutters > .col,
.no-gutters > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: none;
}

.col-1 {
  flex: 0 0 8.333333%;
  max-width: 8.333333%;
}

.col-2 {
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}

.col-5 {
  flex: 0 0 41.666667%;
  max-width: 41.666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.333333%;
  max-width: 58.333333%;
}

.col-8 {
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.333333%;
  max-width: 83.333333%;
}

.col-11 {
  flex: 0 0 91.666667%;
  max-width: 91.666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.333333%;
}

.offset-2 {
  margin-left: 16.666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.333333%;
}

.offset-5 {
  margin-left: 41.666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.333333%;
}

.offset-8 {
  margin-left: 66.666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.333333%;
}

.offset-11 {
  margin-left: 91.666667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-sm-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-sm-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-sm-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-sm-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-sm-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    order: -1;
  }
  .order-sm-last {
    order: 13;
  }
  .order-sm-0 {
    order: 0;
  }
  .order-sm-1 {
    order: 1;
  }
  .order-sm-2 {
    order: 2;
  }
  .order-sm-3 {
    order: 3;
  }
  .order-sm-4 {
    order: 4;
  }
  .order-sm-5 {
    order: 5;
  }
  .order-sm-6 {
    order: 6;
  }
  .order-sm-7 {
    order: 7;
  }
  .order-sm-8 {
    order: 8;
  }
  .order-sm-9 {
    order: 9;
  }
  .order-sm-10 {
    order: 10;
  }
  .order-sm-11 {
    order: 11;
  }
  .order-sm-12 {
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.333333%;
  }
  .offset-sm-2 {
    margin-left: 16.666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.333333%;
  }
  .offset-sm-5 {
    margin-left: 41.666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.333333%;
  }
  .offset-sm-8 {
    margin-left: 66.666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.333333%;
  }
  .offset-sm-11 {
    margin-left: 91.666667%;
  }
}

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-md-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-md-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-md-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-md-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-md-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    order: -1;
  }
  .order-md-last {
    order: 13;
  }
  .order-md-0 {
    order: 0;
  }
  .order-md-1 {
    order: 1;
  }
  .order-md-2 {
    order: 2;
  }
  .order-md-3 {
    order: 3;
  }
  .order-md-4 {
    order: 4;
  }
  .order-md-5 {
    order: 5;
  }
  .order-md-6 {
    order: 6;
  }
  .order-md-7 {
    order: 7;
  }
  .order-md-8 {
    order: 8;
  }
  .order-md-9 {
    order: 9;
  }
  .order-md-10 {
    order: 10;
  }
  .order-md-11 {
    order: 11;
  }
  .order-md-12 {
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.333333%;
  }
  .offset-md-2 {
    margin-left: 16.666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.333333%;
  }
  .offset-md-5 {
    margin-left: 41.666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.333333%;
  }
  .offset-md-8 {
    margin-left: 66.666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.333333%;
  }
  .offset-md-11 {
    margin-left: 91.666667%;
  }
}

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-lg-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-lg-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-lg-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-lg-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-lg-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    order: -1;
  }
  .order-lg-last {
    order: 13;
  }
  .order-lg-0 {
    order: 0;
  }
  .order-lg-1 {
    order: 1;
  }
  .order-lg-2 {
    order: 2;
  }
  .order-lg-3 {
    order: 3;
  }
  .order-lg-4 {
    order: 4;
  }
  .order-lg-5 {
    order: 5;
  }
  .order-lg-6 {
    order: 6;
  }
  .order-lg-7 {
    order: 7;
  }
  .order-lg-8 {
    order: 8;
  }
  .order-lg-9 {
    order: 9;
  }
  .order-lg-10 {
    order: 10;
  }
  .order-lg-11 {
    order: 11;
  }
  .order-lg-12 {
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.333333%;
  }
  .offset-lg-2 {
    margin-left: 16.666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.333333%;
  }
  .offset-lg-5 {
    margin-left: 41.666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.333333%;
  }
  .offset-lg-8 {
    margin-left: 66.666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.333333%;
  }
  .offset-lg-11 {
    margin-left: 91.666667%;
  }
}

@media (min-width: 1441px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-xl-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-xl-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-xl-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-xl-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-xl-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    order: -1;
  }
  .order-xl-last {
    order: 13;
  }
  .order-xl-0 {
    order: 0;
  }
  .order-xl-1 {
    order: 1;
  }
  .order-xl-2 {
    order: 2;
  }
  .order-xl-3 {
    order: 3;
  }
  .order-xl-4 {
    order: 4;
  }
  .order-xl-5 {
    order: 5;
  }
  .order-xl-6 {
    order: 6;
  }
  .order-xl-7 {
    order: 7;
  }
  .order-xl-8 {
    order: 8;
  }
  .order-xl-9 {
    order: 9;
  }
  .order-xl-10 {
    order: 10;
  }
  .order-xl-11 {
    order: 11;
  }
  .order-xl-12 {
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.333333%;
  }
  .offset-xl-2 {
    margin-left: 16.666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.333333%;
  }
  .offset-xl-5 {
    margin-left: 41.666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.333333%;
  }
  .offset-xl-8 {
    margin-left: 66.666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.333333%;
  }
  .offset-xl-11 {
    margin-left: 91.666667%;
  }
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}

@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}
/*# sourceMappingURL=bootstrap-grid.css.map */
:root {
    --main: #3382C5;
    --main-dark: #213C6C;
    --main-light: #45AAFF;
    --acsent: #F35653;
    --acsent-light: #F8F8F8;
}

* {
    box-sizing: border-box;
}

html {
    overflow-x: hidden;
}

body {
    font-family: 'Roboto', sans-serif;
    overflow-x: hidden;
    overflow-y: scroll;
}

img {
    width: 100%;
    height: auto;
    -webkit-user-select: none;
            user-select: none;
}

iframe {
    -webkit-user-select: none;
            user-select: none;
}

form input,
form textarea {
    outline: none;
}

button {
    cursor: pointer;
}

textarea {
    resize: vertical;
}

.container-fluid {
    padding-left: 5%;
    padding-right: 5%;
}

.container-fluid>.row {
    margin-left: 0;
    margin-right: 0;
}

/* VENDOR */

.slick-dots li.slick-active button:before {
    background-color: #5CAFE2;
}

.slick-dots li button:before {
    content: '';
    background-color: gray;
    border-radius: 50%;
}

.slick-dots {
    bottom: -50px;
}

/* EDITOR STYLES */


blockquote {
	border-left: 4px solid #1a1a1a;
	color: #686868;
	font-size: 19px;
	font-style: italic;
	line-height: 1.4736842105;
	margin-bottom: 28px;
	overflow: hidden;
	padding: 0 0 0 24px;
}

blockquote:not(.alignleft):not(.alignright) {
	margin-left: -28px;
}

blockquote blockquote:not(.alignleft):not(.alignright) {
	margin-left: 0;
}

blockquote:before,
blockquote:after {
	content: "";
	display: table;
}

blockquote:after {
	clear: both;
}

blockquote > :last-child {
	margin-bottom: 0;
}

blockquote cite,
blockquote small {
	color: #1a1a1a;
	font-size: 16px;
	line-height: 1.75;
}

blockquote em,
blockquote i,
blockquote cite {
	font-style: normal;
}

blockquote strong,
blockquote b {
	font-weight: 400;
}

blockquote.alignleft,
blockquote.alignright {
	border: 0 solid #1a1a1a;
	border-top-width: 4px;
	padding: 18px 0 0;
	width: calc(50% - 14px);
}

address {
	font-style: italic;
	margin: 0 0 28px;
}

code,
kbd,
tt,
var,
samp,
pre {
	font-family: Inconsolata, monospace;
}

pre {
	border: 1px solid #d1d1d1;
	font-size: 16px;
	line-height: 1.3125;
	margin: 0 0 28px;
	max-width: 100%;
	overflow: auto;
	padding: 14px;
	white-space: pre;
	white-space: pre-wrap;
	word-wrap: break-word;
}

code {
	background-color: #d1d1d1;
	padding: 2px 4px;
}

abbr[title] {
	border-bottom: 1px dotted #d1d1d1;
	cursor: help;
}

mark,
ins {
	background: #007acc;
	color: #fff;
	padding: 2px 4px;
	text-decoration: none;
}

sup,
sub {
	font-size: 75%;
	height: 0;
	line-height: 0;
	position: relative;
	vertical-align: baseline;
}

sub {
	top: -6px;
}

sup {
	bottom: -3px;
}

small {
	font-size: 80%;
}

big {
	font-size: 125%;
}


/**
 * 3.0 - Elements
 */

hr {
	background-color: #d1d1d1;
	border: 0;
	height: 1px;
	margin-bottom: 28px;
}

ul,
ol {
	margin: 0 0 28px 0;
	padding: 0;
}

ul {
	list-style: disc;
}

ol {
	list-style: decimal;
}

li > ul,
li > ol {
	margin-bottom: 0;
}

li > ul,
blockquote > ul {
	margin-left: 20px;
}

li > ol,
blockquote > ol {
	margin-left: 24px;
}

dl {
	margin: 0 0 28px;
}

dt {
	font-weight: bold;
}

dd {
	margin: 0 0 28px;
}

table,
th,
td,
.mce-item-table,
.mce-item-table th,
.mce-item-table td {
	border: 1px solid #d1d1d1;
}

table a {
	color: #007acc;
}

table,
.mce-item-table {
	border-collapse: separate;
	border-spacing: 0;
	border-width: 1px 0 0 1px;
	margin: 0 0 28px;
	width: 100%;
}

table th,
.mce-item-table th,
table caption {
	border-width: 0 1px 1px 0;
	font-size: 16px;
	font-weight: 700;
	padding: 7px;
	text-align: left;
	vertical-align: baseline;
}

table td,
.mce-item-table td {
	border-width: 0 1px 1px 0;
	font-size: 16px;
	padding: 7px;
	vertical-align: baseline;
}

img {
	border: 0;
	height: auto;
	max-width: 100%;
	vertical-align: middle;
}

a img {
	display: block;
}

a.__withoutLine {
  text-decoration: none;
}

figure {
	margin: 0;
}

del {
	opacity: 0.8;
}

fieldset {
	border: 1px solid #d1d1d1;
	margin: 0 0 28px;
	padding: 14px;
}


/**
 * 4.0 - Alignment
 */

.alignleft {
	float: left;
	margin: 6px 28px 28px 0;
}

.alignright {
	float: right;
	margin: 6px 0 28px 28px;
}

.aligncenter {
	clear: both;
	display: block;
	margin: 0 auto 28px;
}


/**
 * 5.0 - Caption
 */

.wp-caption {
	background: transparent;
	border: none;
	margin-bottom: 28px;
	max-width: 100%;
	padding: 0;
	text-align: inherit;
}

.wp-caption-text,
.wp-caption-dd {
	color: #686868;
	font-size: 13px;
	font-style: italic;
	line-height: 1.6153846154;
	padding-top: 7px;
}


/**
 * 6.0 - Galleries
 */

.mce-content-body .wpview-wrap {
	margin-bottom: 28px;
}

.gallery {
	margin: 0 -1.1666667%;
	padding: 0;
}

.gallery .gallery-item {
	display: inline-block;
	max-width: 33.33%;
	padding: 0 1.1400652% 2.2801304%;
	text-align: center;
	vertical-align: top;
	width: 100%;
}

.gallery-columns-1 .gallery-item {
	max-width: 100%;
}

.gallery-columns-2 .gallery-item {
	max-width: 50%;
}

.gallery-columns-4 .gallery-item {
	max-width: 25%;
}

.gallery-columns-5 .gallery-item {
	max-width: 20%;
}

.gallery-columns-6 .gallery-item {
	max-width: 16.66%;
}

.gallery-columns-7 .gallery-item {
	max-width: 14.28%;
}

.gallery-columns-8 .gallery-item {
	max-width: 12.5%;
}

.gallery-columns-9 .gallery-item {
	max-width: 11.11%;
}

.gallery .gallery-caption {
	font-size: 13px;
	margin: 0;
}

.gallery-columns-6 .gallery-caption,
.gallery-columns-7 .gallery-caption,
.gallery-columns-8 .gallery-caption,
.gallery-columns-9 .gallery-caption {
	display: none;
}


/**
 * 7.0 - Audio / Video
 */

.wp-audio-shortcode a,
.wp-playlist a {
	box-shadow: none;
}

.mce-content-body .wp-audio-playlist {
	margin: 0;
	padding-bottom: 0;
}

.mce-content-body .wp-playlist-tracks {
	margin-top: 0;
}

.mce-content-body  .wp-playlist-item {
	padding: 10px 0;
}

.mce-content-body .wp-playlist-item-length {
	top: 10px;
}


/**
 * 8.0 - RTL
 */

.rtl blockquote {
	border: 0 solid #1a1a1a;
	border-right-width: 4px;
}

.rtl blockquote.alignleft,
.rtl blockquote.alignright {
	border: 0 solid #1a1a1a;
	border-top-width: 4px;
}

.rtl blockquote:not(.alignleft):not(.alignright) {
	margin-right: -28px;
	padding: 0 24px 0 0;
}

.rtl blockquote blockquote:not(.alignleft):not(.alignright) {
	margin-right: 0;
	margin-left: auto;
}

.rtl li > ul,
.rtl blockquote > ul {
	margin-right: 20px;
	margin-left: auto;
}

.rtl li > ol,
.rtl blockquote > ol {
	margin-right: 24px;
	margin-left: auto;
}

.rtl table th,
.rtl .mce-item-table th,
.rtl table caption {
	text-align: right;
}

img.size-medium {
    width: 300px;
    height: 275px;
    object-fit: contain;
}

img.size-thumbnail {
    width: 150px; height: 150px;
    object-fit: contain;
}

img.size-full {
		height: unset;
		width: unset;
}
/* ELEMENTS */

main>div:not(#main) {
    margin-top: 120px;
    margin-bottom: 50px;
}

.prev-arrow {
    left: 0;
}

.next-arrow {
    right: 0;
}

.prev-arrow,
.next-arrow {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%)
}

.section-full {
    height: calc(100vh - 100px);
    min-height: 500px;
}

section:not(.section-full) {
    padding: 75px 0;
}

.section-dark {
    background-color: var(--main-dark);
}

.section-light {
    background-color: var(--main-light);
}

.section-acsent {
    background-color: var(--acsent);
}

.section-acsent-light {
    background-color: var(--acsent-light);
}

.section-dark:not(.section-side-left) p,
.section-light:not(.section-side-left) p,
.section-dark:not(.section-side-left) h1,
.section-light:not(.section-side-left) h1,
.section-dark:not(.section-side-left) h2,
.section-light:not(.section-side-left) h2,
.section-dark:not(.section-side-left) a,
.section-light:not(.section-side-left) a,
.section-dark:not(.section-side-left)  .section-title .section-name,
.section-dark:not(.section-side-left)  .section-title .section-description {
    color: white;
}

.section-side-left {
    background: linear-gradient(90deg, var(--main) 25%, white 25%)
}

.section-side-left.section-dark {
    background: linear-gradient(90deg, var(--main-dark) 25%, white 25%)
}

.title-left {
    text-align: left;
}

.section-name {
    font-size: 16px;
    color: gray;
    font-weight: 300;
}

.section-description {
    font-size: 36px;
    margin-top: 0;
}

section p {
    font-size: 16px;
    font-weight: 300;
    line-height: 1.5;
}

.section-title {
    position: relative;
    margin-bottom: 40px;
    padding-bottom: 20px;
}

.title-center {
    text-align: center;
}

.section-title:not(.section-title-simplified)::after {
    content: '';
    background-color: #F07F84;
    height: 1px;
    width: 100px;
    position: absolute;
    bottom: 0;
}

.section-dark:not(.section-side-left) .section-title::after {
    background-color: white;
}

.title-center.section-title::after {
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
}

.button {
    display: block;
    padding: 20px 40px;
    background: none;
    border: none;
    font-size: 14px;
    font-weight: 300;
    text-transform: uppercase;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s;
    text-decoration: none;
}

.button-acsent {
    background-color: var(--acsent);
    border: 1px solid var(--acsent);
}

.button-acsent:hover {
    background-color: rgb(240, 50, 47);
}

.button-light {
    background-color: var(--main-light);
}

.button-light:hover {
    background-color: var(--main);
}

.button-ghost {
    border: 1px solid white;
}

.button-ghost:hover {
    background-color: white;
    color: black;
}

.image-bordered img,
.image-bordered > div,
.image-bordered > video {
    border: 10px solid white;
    box-shadow: 0px 0px 100px rgba(30, 30, 31, 0.15);
}

.filter-item {
    font-size: 16px;
    border: none;
    color: black;
    padding: 10px 0;
    margin: 5px 15px;
    background: none;
}

.filter-item.active {
    border-bottom: 1px solid gray;
    color: gray;
}

.filter-item.hover {
    color: gray;
}

.filter-item:focus {
    outline: none;
    border-bottom: 1px solid gray;
}

/* POSTS */

.post-single {
    overflow: scroll;
}

.post-single p {
    font-size: 24px;
    line-height: 1.5;
}

.post-single-back {
    background: none;
    border: none;
}

/* SECTIONS */

/* HEADER */

header {
    height: 100px;
    overflow: hidden;
    position: fixed;
    top: 0;
    background-color: white;
    z-index: 8;
    box-shadow: 0px 5px 10px rgba(0,0,0,0.3);
}

.header-brand {
    width: 350px;
    height: 100px;
}

.header-nav-item {
    padding: 30px 15px;
    font-size: 14px;
    font-weight: 300;
    color: gray;
    text-transform: uppercase;
    text-decoration: none;
}

.header-nav-item:hover {
    text-decoration: underline;
}

.header-controls {
    text-align: end;
}

.nav-mobile-wrapper {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0,0.8);
    transition: opacity 0.3s ease-in;
    opacity: 0;
    z-index: 10;
}

.nav-mobile {
    position: fixed;
    left: -30vw;
    top: 0;
    width: 30vw;
    background-color: rgb(37, 37, 37);
    height: 100vh;
    transition: left 0.3s ease-out;
}

.header-nav-mobile-item {
    display: block;
    padding: 20px;
    background-color: rgb(53, 53, 53);
    color: white;
    text-decoration: none;
    border-bottom: 1px solid black;
}

.mobile-burger {
    background: none;
    border: none;
    outline: none;
}

.mobile-burger img {
    width: 35px;
    height: 35px;
}

/* BANNER */

#banner {
    margin-top: 100px;
}

#banner .banner-subtitle {
    text-align: center;
}

.banner-logo {
    /* width: 425px; */
    width: 100%;
    height: 190px;
    object-fit: contain;
    margin-bottom: 5px;
}

.banner-title {
    font-size: 14px;
    font-weight: 300;
    text-transform: uppercase;
    text-align: center;
}

.banner-controls button {
    margin: 0 10px
}

/* PROGRESS */

.about-image {
    width: 950px;
    margin-right: 50px;
}

.about-image img {
    min-height: 300px;
    object-fit: cover;
}

.progress-description {
    text-align: center;
}

.progress-step {
    position: relative;
}

.progress-step::after,
.progress-step::before {
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}

.progress-step::before {
    content: '';
    position: absolute;
    bottom: -20px;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    width: 36px;
    height: 6px;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAGCAYAAACrZDylAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAADuSURBVHgBjdO5qsJAFMbx1JcLd4PrgitBVETEBRERC7GxEB/AN7N1wdZG7SwU9+WR4n/kCIOMSQZ+kJx8TDJnMpb1MhzH+cYQC0Qtj0EmjBGW+PWRT2OCGT68wv9Yo40qLrBd8kmcUEYHewRd8gUckEIfK3y9C0ewQ12r5XFFzpDP4qw/47optbghX5P5Y1qthy3+XsO2fHnRMFFKOlXRaiXpjG3IV2QRaa3WwgYBQ74t7w49CzkckbHeDLUqyagONGRrIi55NedNtqgrW/Pjkq9LZxPqZvC48BhqdZhjrP41H3lbDoY6IJ8+8kVM79eLQ2tfr7twAAAAAElFTkSuQmCC);
}

.progress-step::after {
    content: '';
    position: absolute;
    top: 30%;
    right: -30%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    width: 20px;
    height: 40px;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABcAAAApCAYAAADeWrJ9AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABkSURBVHgB7c1BDYBQDATRBQVIQAKOwAk4AUdIQEppoAlfQHshs0nT2xspZmaTKubwau9mJa6P38U/sgPPHN3sGwECBAgQIFAWOAO//AZlzbG9gUdlDRgYGBj4//BSAjeBrQK+AXpLN89fvXT4AAAAAElFTkSuQmCC);
}

.progress-step:last-child::after {
    display: none;
}

/* COMITETS */
.comitets-container {
  /* height: 600px; */
}

.comitets-item {
    margin: 20px 0;
    padding: 0 10px;
    /* height: 200px; */
}

.comitets-item p {
    text-align: center;
}

.comitets-item-thumbnail {
    background-color: var(--main);
    border-radius: 50%;
    box-shadow: rgba(0,0,0,0.2) 0 2px 8px;
    width: 120px;
    height: 120px;
    margin: 0 auto;
}

.comitets-item-thumbnail img {
    width: 64px;
    height: 64px;
    object-fit: contain;
}

/* ABOUT */


.documents-popup-item {
    margin: 20px 0;
}

.documents-popup-item-link {
    color: black;
}

/* PUBLICATIONS */

.publication-item {
    background-size: cover;
    background-position: center;
    margin: 30px 15px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.publication-item .publication-content {
    height: 175px;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 10px 30px;
}

.publication-excerpt {
    color: white;
    font-size: 14px;
    line-height: 1.8;
    font-weight: 300;
    height: 100px;
    overflow-y: auto;
}

.publication-link {
    color: white;
    text-decoration: none;
}

.publication-link:hover {
    border-bottom: 1px solid white;
}

.publication-compact {
    width: 370px;
    height: 320px;
}

.publication-large {
    width: 370px;
    height: 670px;
}

/* EMPLOYER MAP */

.map-privilege {
    margin: 15px;
}

.privilege-icon {
    width: 60px;
    height: 60px;
    background: #FFFFFF;
    border: 0.8px solid #0062A8;
    box-sizing: border-box;
    box-shadow: 0px 3.2px 16px rgba(0, 0, 0, 0.15);
    border-radius: 50%;
    padding: 15px;
    margin: 0 auto;
    display: flex;
    align-items: center;
}

.privilege-description {
    font-size: 11px;
    color: var(--main-dark);
    text-align: center;
}

.map-image {
    position: absolute;
    left: 0;
    top: 0;
    min-width: 300px;
    max-width: 800px;
    width: 50vw;
}

/* MANAGEMENT */

.management-card {
    width: 270px;
    height: 275px;
    margin: 12px;
    display: flex;
    align-items: flex-end;
    background-position: center;
    background-size: cover;
    position: relative;
    object-fit: cover;
}

.management-card:first-child {
    margin-left: 0;
}

.management-leader-name {
    font-size: 24px;
    margin: 0;
}

.management-leader-description p {
    margin-top: 7vh;
    font-size: 18px;
    font-weight: 400;
    white-space: pre-line;
    max-height: 200px;
    overflow: auto;
}

.management-info {
    background: rgba(0, 0, 0, 0.5);
    height: 70px;
    width: 100%;
    text-align: center;
}

.management-info .management-name {
    font-size: 16px;
    color: white;
    font-weight: 300;
    margin-bottom: 5px;
}

.management-info .management-post {
    font-size: 14px;
    color: white;
    font-weight: 300;
    margin-top: 5px;
}

.management-leader-photo {
    margin-right: 24px;
}

.management-all-title {
    text-align: center;
    text-transform: uppercase;
    font-weight: 300;
    position: relative;
    padding-bottom: 20px;
    margin-bottom: 50px;
}

.management-all-title::after {
    content: '';
    background-color: var(--acsent);
    height: 1px;
    width: 100px;
    position: absolute;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    left: 50%;
    bottom: 0;
}

.management-section {
    border-bottom: 1px solid lightgray;
}

.management-section:last-child {
    border: none;
}

.management-section ul {
    list-style: none;
    margin-left: 0;
}

.management-section li {
    padding: 10px 0;
}

.management-file-link {
    text-transform: uppercase;
    font-size: 1.3rem;
    text-align: center;
    color: black;
}

/* ACHIEVES | REVIEWS */

.achieve-icon {
    width: 45px;
    height: 45px;
    object-fit: contain;
}

.achieve-number {
    font-size: 42px;
    font-weight: 400;
    margin: 0;
    text-align: center;
}

.achieve-description {
    font-size: 16px;
    color: gray;
    margin-top: 10px;
    text-align: center;
    height: 75px;
    overflow-y: auto;
}

.achievement-item {
    padding: 50px 60px;
    border: 1px solid lightgray;
}

.achievement-item.__withHover:hover {
	box-shadow: inset 2px 5px 16px 0px #0b325e, inset 5px 5px 15px 5px rgba(0, 0, 0, 0);
}

.author-photo {
    width: 83px;
    height: 83px;
    border-radius: 50%;
    margin-left: auto;
    margin-right: auto;
}

.author-name {
    color: white;
    font-size: 18px;
    font-weight: 300;
    text-align: center;
}

.author-occupation {
    color: white;
    font-size: 14px;
    font-weight: 300;
    text-align: center;
}

.review-text {
    text-align: center;
    font-size: 18px;
    line-height: 2;
}

.review-wrapper {
    max-width: 500px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: 0 auto;
}

.author-social-item {
    margin: 5px;
}

.achievments-wrapper {
    margin-left: 0;
    margin-right: 0;
}

/* STEPS */

.step {
    background: #FFFFFF;
    box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.15);
    border-radius: 50px;
    padding: 10px 30px;
    width: 300px;
    height: 90px;
    margin: 0 80px;
    position: relative;
}

.step:last-child {
    margin-right: 0;
}

.step:first-child {
    margin-left: 0;
}

.step-content {
    color: #F35653;
    text-align: center;
    margin: 0;
}

.step:after {
    content: '';
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    width: 20px;
    height: 40px;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABcAAAApCAYAAADeWrJ9AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABkSURBVHgB7c1BDYBQDATRBQVIQAKOwAk4AUdIQEppoAlfQHshs0nT2xspZmaTKubwau9mJa6P38U/sgPPHN3sGwECBAgQIFAWOAO//AZlzbG9gUdlDRgYGBj4//BSAjeBrQK+AXpLN89fvXT4AAAAAElFTkSuQmCC);
    position: absolute;
    top: 50%;
    right: -30%;
}

.step:last-child::after {
    display: none;
}

/* PAYMENT */

.payment-item {
    width: 350px;
    background: #FFFFFF;
    box-shadow: 0px 0px 50px rgba(92, 175, 226, 0.4);
}

.payment-header {
    border-bottom: 1px solid gray;
}

.payment-name {
    font-size: 18px;
    font-weight: 300;
    letter-spacing: 5.5px;
    text-align: center;
    text-transform: uppercase;
    margin-top: 40px;
    margin-bottom: 10px;
}

.payment-price {
    font-size: 36px;
    font-weight: 400;
    text-align: center;
    margin-top: 10px;
}

.payment-price>span {
    color: lightgray;
    font-size: 14px;
}

.payment-content {
    padding: 50px;
    padding-top: 20px;
}

.payment-content-description {
    font-size: 14px;
    line-height: 2;
    color: gray;
    margin-bottom: 30px;
    text-align: center;
}

.payments-documents ul {
    list-style: none;
}

.payments-documents li {
    padding: 20px 0;
}

.payments-documents a {
    color: black;
}

/* PARTNERS */

.partners-container {
  padding-bottom: 50px;
}

.partner-item {
    height: 100px !important;
    background-color: white;
    border-radius: 20px;
    box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.15);
    padding: 5px 10px;
    position: relative;
}

.partner-item a {
    width: 100%;
    height: 100%;
}

.partner-item img {
    object-fit: contain;
    width: 100%;
    height: 100%;
}

.partners-video {
    padding: 0 20px;
    min-height: 50vh;
}

.partners-video video {
    min-height: 40vh;
}

/* CONTACTS */

.contact-input-row {
    margin-top: 15px;
    margin-bottom: 15px;
}

.contact-input-item,
.contact-input-text {
    width: 100%;
    border: 1px solid #ECECEC;
    padding: 15px;
}

.contacts-map {
    min-height: 350px
}

.social-contact-logolink {
    width: 50px;
    height: 50px;
}

/* ADRESS */
.adress-city {
    font-size: 36px;
    text-transform: uppercase;
    text-align: center;
    font-weight: 300;
}

.adress-description {
    text-align: center;
    font-size: 14px;
    line-height: 2;
}

/* BUREAU */

.bureau-leader {
    margin-top: 0.35em;
}

.bureau-leader-image {
    width: 260px;
    height: 260px;
}


/* FOOTER */
footer {
    padding: 75px 0 20px 0;
}

.footer-title-wrapper {
    position: relative;
    margin-bottom: 50px;
}

.footer-title-wrapper::after {
    content: '';
    width: 42px;
    height: 4px;
    background-color: white;
    position: absolute;
    bottom: -25px;
    left: 0;
}

.footer-title {
    font-size: 24px;
    color: white;
}

.footer-links-item {
    margin: 15px 0;
}

.footer-links-item-link {
    color: darkgray;
    font-size: 14px;
    text-decoration: none;
    padding: 20px;
    padding-left: 0;
}

.footer-links-item:hover {
    text-decoration: underline;
}

.footer-letter-input-submit-button,
.footer-letter-input-field {
    background: none;
    border: none;
    padding: 0 0 10px 0;
}

.footer-letter-input-field {
    font-size: 14px;
    font-weight: 400;
    color: white;
}

.footer-letter-input-submit-button img {
    width: 20px;
    height: 15px;
    margin-left: auto;
    display: block;
}

.footer-letter-input {
    border-bottom: 2px solid white;
}

.footer-social-item {
    margin: 0 10px;
}

.footer-credits-text,
.footer-credits-text a {
    color: #75757D;
    font-size: 14px;
}

.footer-credits-text span,
.footer-credits-text a {
    color: white;
    text-decoration: none;
}

.footer-credits-text.contact {
    text-align: right;
}

/* XL */
@media(max-width: 1440px) {
    .progress-description {
        font-size: 14px;
    }

    .step {
        margin: 0 40px;
        width: 200px;
    }

    .step:after {
        right: -25%;
    }
}

/* LG */
@media(max-width: 1200px) {
    .publication-item {
        min-height: 320px;
        padding: 0;
    }

    .publication-item .publication-content {
        padding: 0 30px;
    }

    .about-image {
        padding-right: 0;
    }
    .step {
        margin: 20px 0;
    }

    .step:after {
        display: none;
    }

}

/* MD */
@media(max-width: 992px) {
    .section-side-left.section-dark {
        background: linear-gradient(to bottom, var(--main-dark) 45%, white 45%)
    }
    .section-side-left {
        background: linear-gradient(to bottom, var(--main) 45%, white 45%)
    }

    .progress-step {
        margin: 30px 0;
    }

    .progress-step::after {
        display: none;
    }
 
    .map-image {
        position: relative;
    }

    .footer-credits-text,
    .footer-credits-text.contact {
        text-align: center;
    }
}

/* SM */
@media(max-width: 568px) {
    .review-wrapper {
        max-width: 320px;
    }
    .header-brand {
        width: 200px;
    }
    .section-side-left {
        background: linear-gradient(to bottom, var(--main) 15%, white 15%)
    }
    .section-side-left.section-dark {
        background: linear-gradient(to bottom, var(--main-dark) 15%, white 15%)
    }

    .section-content {
        padding-left: 10px !important;
        padding-right: 10px !important;
    }

    .achieve-number {
        font-size: 32px;
    }

    .nav-mobile {
        width: 65vw;
    }
    
    .management-leader-photo {
        margin-right: 0;
    }

    .review-item {
        padding: 0 15px;
    }

    .contact-input-row input {
        margin-top: 15px;
        margin-bottom: 15px;
    }

    .button {
        padding: 20px 30px;
    }

    .publication-item .publication-content {
        padding: 0 10px;
    }
    .achieve-number {
        font-size: 32px;
    }

    .publication-compact {
        width: 300px;
    }

    .map-privilege {
        width: 100px !important;
    }

    .payment-item {
        width: 280px;
    }
    .payments-documents ul,
    .management-section ul {
        padding-left: 0;
    }
}


.swiper-pagination-bullet {
  width: 18px;
  height: 18px;
}

.swiper-container-horizontal>.swiper-pagination-bullets, .swiper-pagination-custom, .swiper-pagination-fraction {
  bottom: 0;
}

/* ----------------------------------------------------------- */
/* == tingle v0.13.2 */
/* ----------------------------------------------------------- */

.tingle-modal * {
  box-sizing: border-box;
}

.tingle-modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  display: flex;
  visibility: hidden;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  background: rgba(0, 0, 0, .8);
  opacity: 0;
  cursor: pointer;
  transition: -webkit-transform .2s ease;
  transition: transform .2s ease;
  transition: transform .2s ease, -webkit-transform .2s ease;
}

/* confirm and alerts
-------------------------------------------------------------- */

.tingle-modal--confirm .tingle-modal-box {
  text-align: center;
}

/* modal
-------------------------------------------------------------- */

.tingle-modal--noOverlayClose {
  cursor: default;
}

.tingle-modal--noClose .tingle-modal__close {
  display: none;
}

.tingle-modal__close {
  position: fixed;
  top: 10px;
  right: 28px;
  z-index: 1000;
  padding: 0;
  width: 5rem;
  height: 5rem;
  border: none;
  background-color: transparent;
  color: #f0f0f0;
  font-size: 6rem;
  font-family: monospace;
  line-height: 1;
  cursor: pointer;
  transition: color .3s ease;
}

.tingle-modal__closeLabel {
  display: none;
}

.tingle-modal__close:hover {
  color: #fff;
}

.tingle-modal-box {
  position: relative;
  flex-shrink: 0;
  margin-top: auto;
  margin-bottom: auto;
  width: 60%;
  border-radius: 4px;
  background: #fff;
  opacity: 1;
  cursor: auto;
  transition: -webkit-transform .3s cubic-bezier(.175, .885, .32, 1.275);
  transition: transform .3s cubic-bezier(.175, .885, .32, 1.275);
  transition: transform .3s cubic-bezier(.175, .885, .32, 1.275), -webkit-transform .3s cubic-bezier(.175, .885, .32, 1.275);
  -webkit-transform: scale(.8);
          transform: scale(.8);
}

.tingle-modal-box__content {
  padding: 3rem 3rem;
}

.tingle-modal-box__footer {
  padding: 1.5rem 2rem;
  width: auto;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  background-color: #f5f5f5;
  cursor: auto;
}

.tingle-modal-box__footer::after {
  display: table;
  clear: both;
  content: "";
}

.tingle-modal-box__footer--sticky {
  position: fixed;
  bottom: -200px; /* TODO : find a better way */
  z-index: 10001;
  opacity: 1;
  transition: bottom .3s ease-in-out .3s;
}

/* state
-------------------------------------------------------------- */

.tingle-enabled {
  position: fixed;
  overflow: hidden;
  left: 0;
  right: 0;
}

.tingle-modal--visible .tingle-modal-box__footer {
  bottom: 0;
}

.tingle-enabled .tingle-content-wrapper {
  -webkit-filter: blur(8px);
          filter: blur(8px);
}

.tingle-modal--visible {
  visibility: visible;
  opacity: 1;
}

.tingle-modal--visible .tingle-modal-box {
  -webkit-transform: scale(1);
          transform: scale(1);
}

.tingle-modal--overflow {
  overflow-y: scroll;
  padding-top: 8vh;
}

/* btn
-------------------------------------------------------------- */

.tingle-btn {
  display: inline-block;
  margin: 0 .5rem;
  padding: 1rem 2rem;
  border: none;
  background-color: grey;
  box-shadow: none;
  color: #fff;
  vertical-align: middle;
  text-decoration: none;
  font-size: inherit;
  font-family: inherit;
  line-height: normal;
  cursor: pointer;
  transition: background-color .4s ease;
}

.tingle-btn--primary {
  background-color: #3498db;
}

.tingle-btn--danger {
  background-color: #e74c3c;
}

.tingle-btn--default {
  background-color: #34495e;
}

.tingle-btn--pull-left {
  float: left;
}

.tingle-btn--pull-right {
  float: right;
}

/* responsive
-------------------------------------------------------------- */

@media (max-width : 540px) {
  .tingle-modal {
    top: 0px;
    display: block;
    padding-top: 60px;
    width: 100%;
  }

  .tingle-modal-box {
    width: auto;
    border-radius: 0;
  }

  .tingle-modal-box__content {
    overflow-y: scroll;
  }

  .tingle-modal--noClose {
    top: 0;
  }

  .tingle-modal--noOverlayClose {
    padding-top: 0;
  }

  .tingle-modal-box__footer .tingle-btn {
    display: block;
    float: none;
    margin-bottom: 1rem;
    width: 100%;
  }

  .tingle-modal__close {
    top: 0;
    right: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 60px;
    border: none;
    background-color: #2c3e50;
    box-shadow: none;
    color: #fff;
    line-height: 55px;
  }

  .tingle-modal__closeLabel {
    display: inline-block;
    vertical-align: middle;
    font-size: 1.5rem;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  }

  .tingle-modal__closeIcon {
    display: inline-block;
    margin-right: .5rem;
    vertical-align: middle;
    font-size: 4rem;
  }
}

@supports ((-webkit-backdrop-filter: blur(12px)) or (backdrop-filter: blur(12px))) {
  .tingle-modal {
    -webkit-backdrop-filter: blur(20px);
            backdrop-filter: blur(20px);
  }

  @media (max-width : 540px) {
    .tingle-modal {
      -webkit-backdrop-filter: blur(8px);
              backdrop-filter: blur(8px);
    }
  }

  .tingle-enabled .tingle-content-wrapper {
    -webkit-filter: none;
            filter: none;
  }
}

.video-item h2,
.video-item p {
  text-align: center;
}

.wp-video {
  display: block;
  margin: 20px auto;

  border: 10px solid var(--main-dark);
  box-shadow: 0px 0px 100px rgba(30, 30, 31, 0.15);

  box-sizing: content-box;
}
.project-wrapper {
	position: relative;
	min-height: 250px;

	background-position: center;
  background-size: cover;
  
  margin-bottom: 20px;
}

.project-wrapper:hover {
	box-shadow: 2px 5px 16px 0px #0b325e, 5px 5px 15px 5px rgba(0, 0, 0, 0);
}

.project-title {
	position: absolute;
	width: calc(100% + 5px);
	bottom: 10px;
	left: -5px;

	padding: 10px 5px;
	background-color: rgb(26, 26, 38);
	color: white;
}

